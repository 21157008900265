import React, { useEffect, useState, useMemo, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import CloseIcon from '@assets/icons/Close';
import dealsService from '@lib/services/deals-service';
import { useUserRole } from '@lib/hooks/useUserRole';
import { useWindowSize } from '@lib/hooks/useWindowSize';
import ConfirmationPopup from '@organisms/Popup/ConfirmationPopup/confirmation-popup.component';
import Popup from '@organisms/Popup/popup.component';
import Heading from '@atoms/Headings/heading.component';
import IconOnlyButton from '@atoms/Buttons/AddButton/add-button.component';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

//import './styles.scss';
import styles from './ledger-log.module.scss';
import dayjs from 'dayjs';
import { CreateEditLedgerLog } from './create-edit-log';
import { formatNumber } from '@lib/helpers/format-number.helper';
import { MenuButton } from '@atoms/MenuButton/menu-button.component';
import { Divider } from '@material-ui/core';
import { IconButton, Menu, MenuItem } from '@mui/material';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

function LedgerLog({ dealId, reloadMessages }) {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { isAdmin } = useUserRole();

  const [deletingStep, setDeletingStep] = useState(false);
  const [alert, setAlert] = useState({});
  const [steps, setSteps] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [showCreateEditPage, setShowCreateEditPage] = useState({
    visible: false,
    log: null,
  });
  const [logType, setLogType] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const clickHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeHandler = (onClick) => {
    typeof onClick === 'function' && onClick();
    setAnchorEl(null);
  };

  const getData = async () => {
    dealsService
      .getLedgerLogs(dealId)
      .then((response) => {
        setSteps(response.data);
      })
      .catch((e) => {
        if (e.response.data.status === 500) {
          setAlert({
            state: 'error',
            message: t(`Server error! Please contact our support team.`),
          });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        } else {
          setAlert({ state: 'error', message: e.response.data.message });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        }
      });

    dealsService
      .getLedgerLogTypes()
      .then((res) => {
        const items = [];
        Object.entries(res.data).map((element) => {
          items.push({
            name: element[1],
            onClick: () => {
              setLogType(element);
              setShowCreateEditPage({ visible: true, log: null });
            },
          });
        });
        setMenuItems(items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteLog = (logId) => {
    dealsService
      .deleteLedgerLog(dealId, deletingStep.Id || logId)
      .then((r) => {
        setDeletingStep(false);
        setShowCreateEditPage({ visible: false, log: null });
        getData();
        reloadMessages && reloadMessages();
        setAlert({
          state: 'success',
          message: t(`The Ledger log was deleted successfully!`),
        });
        setTimeout(() => {
          setAlert({});
        }, 4000);
      })
      .catch((e) => {
        if (e.response.status === 500) {
          setAlert({
            state: 'error',
            message: t(`Server error! Please contact our support team.`),
          });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        } else {
          setAlert({ state: 'error', message: e.response.data.message });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        }
      });
  };

  useEffect(() => {
    getData();
  }, [showCreateEditPage.visible]);

  return (
    <React.Fragment>
      {deletingStep && (
        <ConfirmationPopup
          message={t('Are you sure you want to delete this log?')}
          cancelationHandler={() => {
            setDeletingStep(false);
          }}
          agreementHandler={deleteLog}
          dealId={dealId}
        />
      )}
      <div className='steps-list'>
        {showCreateEditPage.visible ? (
          <CreateEditLedgerLog
            log={showCreateEditPage.log}
            setIsVisible={setShowCreateEditPage}
            deleteLog={deleteLog}
            dealId={dealId}
            reloadMessages={reloadMessages}
            type={logType}
            setType={setLogType}
          />
        ) : (
          <>
            <div className={styles.headingContainer}>
              <div>
                <Heading type={'h1'}>{t('Ledger')}</Heading>
                <p>
                  Financed: {steps.FinancedDays} | Outstanding: $
                  {steps.OutstandingAmount}
                </p>
              </div>

              <IconOnlyButton
                handleClick={(event) => {
                  clickHandler(event);
                }}
                tooltip={t('Create a new Ledger Log')}>
                <ControlPointOutlinedIcon />
              </IconOnlyButton>
              <StyledMenu
                id='three-dots-menu'
                MenuListProps={{
                  'aria-labelledby': 'three-dots-menu',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={closeHandler}>
                {menuItems.map((item, index) =>
                  item.divider ? (
                    <Divider sx={{ my: 0.5 }} key={`divider-${index}`} />
                  ) : (
                    <MenuItem
                      key={item?.name}
                      onClick={closeHandler.bind(this, item?.onClick)}
                      disableRipple>
                      <div style={{ marginLeft: 10 }}>{item.name}</div>
                    </MenuItem>
                  )
                )}
              </StyledMenu>
            </div>
            <Popup message={alert.message} state={alert.state} />
            {steps &&
              Object.keys(steps).length > 0 &&
              Object.keys(steps).map((group, index1) => {
                if (
                  Object.keys(steps[group]).length > 0 &&
                  group !== 'FinancedDays' &&
                  group !== 'OutstandingAmount'
                ) {
                  return (
                    <div className='step-group-container' key={index1}>
                      <p className='step-group'>{t(group)}</p>
                      <div className='steps'>
                        {Object.keys(steps[group]).map((key, index2) => {
                          const symbol = steps[group][key].Amount.includes('-')
                            ? '-'
                            : '+';
                          const isSettled =
                            steps[group][key].IsSettled === '0' ? false : true;
                          const summary = `${dayjs(
                            steps[group][key].ValueDate
                          ).format('DD/MM/YYYY')} | ${
                            steps[group][key].Concept.length > 40
                              ? steps[group][key].Concept.substring(0, 40) +
                                '...'
                              : steps[group][key].Concept
                          } | ${
                            steps[group][key].CounterParty.length > 40
                              ? steps[group][key].CounterParty.substring(
                                  0,
                                  40
                                ) + '...'
                              : steps[group][key].CounterParty
                          } | ${
                            steps[group][key].Account.length > 40
                              ? steps[group][key].Account.substring(0, 40) +
                                '...'
                              : steps[group][key].Account
                          }`;

                          return (
                            <div className='step' key={index2}>
                              <div
                                className='step-content'
                                onClick={() => {
                                  setShowCreateEditPage({
                                    visible: true,
                                    log: steps[group][key],
                                  });
                                  window.scrollTo(0, 0);
                                }}>
                                <div
                                  className={`step-logo ${
                                    isSettled ? 'done' : ''
                                  }`}>
                                  {symbol}
                                </div>
                                <div className='step-border'>
                                  <div className={styles.stepInfo}>
                                    <div className={styles.heading}>
                                      <h5>{`$${formatNumber(
                                        Math.abs(steps[group][key].Amount)
                                      )}`}</h5>
                                      <p
                                        className={`${styles.serviceConcept} ${
                                          symbol === '-'
                                            ? styles.gray
                                            : styles.purple
                                        }`}>
                                        {steps[group][key].ServiceConcept}
                                      </p>
                                    </div>
                                    <p
                                      style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                      }}>
                                      {summary}
                                    </p>
                                  </div>
                                  <div className='step-actions'>
                                    <>
                                      {isAdmin && (
                                        <div
                                          className='remove-step'
                                          onClick={(e) => {
                                            setDeletingStep(steps[group][key]);
                                            e.stopPropagation();
                                          }}>
                                          {width > 710 ? (
                                            <CloseIcon />
                                          ) : (
                                            <button className='step-button'>
                                              {t('DELETE')}
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else return <React.Fragment></React.Fragment>;
              })}
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default LedgerLog;
