import { useAppStore } from "@lib/Context";
import { useMemo } from "react";

export const useUserRole = () => {
  const { state } = useAppStore();

  const isAdmin = useMemo(() => {
    return state.userInfo?.isAdmin;
  }, [state.userInfo]);

  const isOrgAdmin = useMemo(() => {
    return state.userInfo?.isOrgAdmin;
  }, [state.userInfo]);

  const isTrader = useMemo(() => {
    return state.userInfo?.isTrader;
  }, [state.userInfo]);

  const isFinancier = useMemo(() => {
    return state.userInfo?.isFinancier;
  }, [state.userInfo]);

  const isInsurer = useMemo(() => {
    return state.userInfo?.isInsurer;
  }, [state.userInfo]);

  const isBuyer = useMemo(() => {
    if (state.userInfo?.userTypeInDeal === "buyer") {
      return true;
    }

    return state.userInfo?.isBuyer || false;
  }, [state.userInfo]);

  const isSeller = useMemo(() => {
    if (state.userInfo?.userTypeInDeal === "seller") {
      return true;
    }

    return state.userInfo?.isSeller || false;
  }, [state.userInfo]);

  const isWarehouseAgent = useMemo(() => {
    return state.userInfo?.isWarehouseAgent;
  }, [state.userInfo]);

  return {
    isAdmin,
    isTrader,
    isBuyer,
    isSeller,
    isOrgAdmin,
    isFinancier,
    isInsurer,
    isWarehouseAgent,
  };
};
