import { useCallback, memo } from 'react';

import { DocumentFile } from '@organisms/Documents/DocumentFile/document-file.component';
import { UploadDocument } from '@organisms/Dialogs/UploadDocument/upload-document.component';
import styles from '@organisms/Documents/SingleDocument/single-document.style.module.scss';
import { useUserRole } from '@lib/hooks/useUserRole';

const Component = ({
  documentType,
  onRefreshDocuments,
  stepDocument,
  deleteDocument,
}) => {
  const { isFinancier, isInsurer, isWarehouseAgent } = useUserRole();
  const renderFields = useCallback(() => {
    return (
      <>
        {stepDocument &&
        stepDocument?.Files &&
        stepDocument?.Files.length > 0 ? (
          stepDocument.Files.map((file) => {
            return (
              <DocumentFile
                key={file.Id}
                stepDocument={stepDocument}
                noEditAction={false}
                file={file}
                showStatus={false}
                onDeleteDocument={deleteDocument}
                onRefreshDocuments={onRefreshDocuments}
                type={'create'}
                documentType={documentType}
                canWrite={!isFinancier && !isInsurer && !isWarehouseAgent}
              />
            );
          })
        ) : (
          <div className={styles.uploadFileWrap}>
            <UploadDocument
              isEdit={false}
              type={'create'}
              documentType={documentType}
              onRefreshDocuments={onRefreshDocuments}
              canWrite={!isFinancier && !isInsurer && !isWarehouseAgent}
            />
          </div>
        )}
      </>
    );
  }, [stepDocument]);

  return <div className={styles.group}>{renderFields()}</div>;
};

export const CreatePageSingleDocument = memo(Component);
