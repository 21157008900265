/**
 * Quote Step Component
 * description: Form used inside Create Deal and Quick Step Inside Manage Deal
 * @props buttons: show or hide the default quickStep buttons
 * @props onBack: if we have default buttons activated decide what the back button will do from the parent component
 * @props submit: if we have default buttons activated decide what the submit button will do from the parent component
 * @props loader: the parent component will tell us if we need to show the loader instead of the buttons for 1s
 * @props errors: bring the input errors
 * @props quoteFields: the main fields which are outside the accordions
 * @props quoteProduct: the products fields which are inside the accordions
 * @props setFields: set the products fields needed to the api on submit
 * @props setMainFields: set the main fields needed to the api on submit
 * @props step: on which step are you at the moment : 'create' | any
 * @props setChildProducts: send the current products to the parent in order to use them later again, because we change them inside the component
 * @props childProducts: use the products sent before.
 */

import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

import dealsService from '@services/deals-service';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { usePopup } from '@lib/hooks/usePopup';
import { stepStatusActive } from '@base/globalVariables/global-variables';
import IconOnlyButton from '@atoms/Buttons/AddButton/add-button.component';
import Heading from '@atoms/Headings/heading.component';
import ConfirmationPopup from '@organisms/Popup/ConfirmationPopup/confirmation-popup.component';
import APRStepForm from '@organisms/Steps/APRStep/APRStepForm/apr-step-form.component';
import SampleDeleteBtn from '@organisms/SampleDeleteBtn/sample-delete-btn.component';

import styles from './apr-step-samples.module.scss';
import { useUserRole } from '@lib/hooks/useUserRole';

const APRStepSamples = ({
  samples,
  archivedDeal,
  setUpdateAPR,
  onSubmit,
  canWrite,
}) => {
  const params = useParams();
  const { deal } = useManageDealContext();
  const { t } = useTranslation();
  const { showPopup } = usePopup();
  const { isFinancier, isInsurer, isWarehouseAgent } = useUserRole();

  const [currentAPR, setCurrentAPR] = useState(0); // for the accordion open logic
  const [newSample, setNewSample] = useState(false); // to know when newSample is being created

  const createNewSample = () => {
    dealsService
      .createNewSample(params.id, {
        Id: [samples[currentAPR].Step.Id],
      })
      .then(() => {
        setNewSample(false);
        setUpdateAPR(true);
        showPopup({
          state: 'success',
          message: t('New sample added!'),
          timer: 4000,
        });
      })
      .catch((e) => {
        showPopup({
          state: 'error',
          message: t(e.response.data),
          timer: 4000,
        });
      });
  };

  const changeCurrentSample = (index) => {
    setCurrentAPR(index);
  };

  useEffect(() => {
    if (!samples?.length) {
      return;
    }
    const sampleIndex = samples.findIndex(
      (sample) => sample.Step.State === 'active'
    );

    const index = sampleIndex !== -1 ? sampleIndex : 0;
    changeCurrentSample(index);
  }, [samples, deal]);

  return (
    <div className={styles.container}>
      {newSample && (
        <ConfirmationPopup
          message={t('Are you sure you want to create a new sample?')}
          cancelationHandler={() => {
            setNewSample(false);
          }}
          agreementHandler={createNewSample}
        />
      )}
      <Heading type={'h2'}>{t('SampleManagement')}</Heading>
      {samples?.length > 0 && (
        <div className={styles.stepsPagination}>
          {samples?.map((item, i) => (
            <div
              className={`${styles.button} ${styles[item?.Step?.State] || ''} ${
                currentAPR === i ? styles.current : ''
              }`}
              key={i}
              onClick={changeCurrentSample.bind(this, i)}>
              <InventoryIcon />
              {item?.Step?.Status === stepStatusActive &&
                !item?.Step?.AlreadySubmitted &&
                samples.length > 1 &&
                i > 0 &&
                canWrite === true &&
                !isFinancier &&
                !isInsurer &&
                !isWarehouseAgent && (
                  <SampleDeleteBtn
                    stepId={item.Step?.DealId}
                    sampleId={item.Step?.Id}
                    setUpdateAPR={setUpdateAPR}
                  />
                )}
            </div>
          ))}
          {!archivedDeal &&
            canWrite === true &&
            !isFinancier &&
            !isInsurer &&
            !isWarehouseAgent && (
              <IconOnlyButton
                handleClick={() => setNewSample(true)}
                tooltip={t('Create New Price Confirmation')}>
                <ControlPointOutlinedIcon />
              </IconOnlyButton>
            )}
        </div>
      )}
      {samples?.length > 0 && (
        <APRStepForm sample={samples[currentAPR]} onSubmit={onSubmit} />
      )}
    </div>
  );
};

export default memo(APRStepSamples);
