import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ledger-log.module.scss';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import FormInput from '@atoms/Fields/ReactFormFields/FormInput/form-input.component';
import Heading from '@atoms/Headings/heading.component';
import FormDate from '@atoms/Fields/ReactFormFields/FormDate/form-date.component';
import Button from '@atoms/Buttons/Button';
import dealsService from '@lib/services/deals-service';
import Popup from '@organisms/Popup/popup.component';
import ConfirmationPopup from '@organisms/Popup/ConfirmationPopup/confirmation-popup.component';

export const CreateEditLedgerLog = ({
  log,
  setIsVisible,
  dealId,
  deleteLog,
  reloadMessages,
  type,
  setType,
}) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState({});
  const [deletingLog, setDeletingLog] = useState(false);
  const [changingSettled, setChangingSettled] = useState(false);
  const [settledStatus, setSettledStatus] = useState(
    !!Number(log?.IsSettled) || false
  );
  const requiredField = t('Field is required');

  const form = useForm({
    defaultValues: {
      Amount: log?.Amount,
      ServiceConcept: log?.ServiceConcept,
      Concept: log
        ? log?.Concept
        : !!type?.length && type[1] !== 'Other'
        ? type[1]
        : '',
      Account: log?.Account,
      CounterParty: log?.CounterParty,
      ValueDate: log?.ValueDate || Date.now(),
      PaymentRef: log?.PaymentRef,
      InvoiceRef: log?.InvoiceRef,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSubmitLog = async (formData) => {
    const payload = {
      ...formData,
      Type: type[0] || log.Type,
    };
    const service = !!log
      ? dealsService.updateLedgerLog(dealId, log.Id, payload)
      : dealsService.createLedgerLog(dealId, payload);

    service
      .then((response) => {
        if (response.status === 200) {
          reloadMessages && reloadMessages();
          setType([]);
          setIsVisible({ visible: false, log: null });
        }
      })
      .catch((e) => {
        if (e.response.status === 500) {
          setAlert({
            state: 'error',
            message: t('SomethingWentWrongWeApologize'),
          });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        } else {
          setAlert({ state: 'error', message: e.response.data.message });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        }
      });
  };

  const deleteLedgerLog = async () => {
    deleteLog(log?.Id);
  };

  const changeSettledStatus = async () => {
    const payload = {
      IsSettled: !settledStatus,
    };
    dealsService
      .settleLedgerLog(log?.DealId, log.Id, payload)
      .then((res) => {
        reloadMessages && reloadMessages();
        setChangingSettled(false);
        setType([]);
        setIsVisible({
          visible: false,
          log: null,
        });
        //setSettledStatus(!settledStatus);
      })
      .catch((e) => {
        if (e.response.status === 500) {
          setAlert({
            state: 'error',
            message: t('SomethingWentWrongWeApologize'),
          });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        } else {
          setAlert({ state: 'error', message: e.response.data.message });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        }
      });
  };

  return (
    <>
      {deletingLog && (
        <ConfirmationPopup
          message={t('Are you sure you want to delete this log?')}
          cancelationHandler={() => {
            setDeletingLog(false);
          }}
          agreementHandler={deleteLedgerLog}
        />
      )}
      {changingSettled && (
        <ConfirmationPopup
          message={t('Are you sure you want to change the status of this log?')}
          cancelationHandler={() => {
            setChangingSettled(false);
          }}
          agreementHandler={changeSettledStatus}
        />
      )}
      <Popup message={alert.message} state={alert.state} />
      <div className={styles.container}>
        <FormProvider {...form}>
          <div className={styles.createFormHeading}>
            <h2 style={{ margin: 0 }}>{t('LedgerRecord')}</h2>
            <Button
              type={'link'}
              size={'small'}
              onClick={() => {
                setType([]);
                setIsVisible({
                  visible: false,
                  log: null,
                });
              }}>
              {t('Back')}
            </Button>
          </div>
          <Grid md={12}>
            <Controller
              name='Amount'
              rules={{
                required: {
                  value: true,
                  message: requiredField,
                },
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label={t('Amount')}
                  type={'number'}
                  required={true}
                  errors={errors['Amount']?.message}
                />
              )}
            />
            <Controller
              name='ServiceConcept'
              rules={{
                required: {
                  value: true,
                  message: requiredField,
                },
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label={t('Service Concept')}
                  required={true}
                  errors={errors['ServiceConcept']?.message}
                />
              )}
            />
            <Controller
              name='Concept'
              rules={{
                required: {
                  value: true,
                  message: requiredField,
                },
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label={t('Concept')}
                  required={true}
                  errors={errors['Concept']?.message}
                />
              )}
            />
            <Controller
              name='Account'
              rules={{
                required: {
                  value: true,
                  message: requiredField,
                },
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label={t('Account')}
                  required={true}
                  errors={errors['Account']?.message}
                />
              )}
            />
            <Controller
              name='CounterParty'
              rules={{
                required: {
                  value: true,
                  message: requiredField,
                },
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label={t('Counter Party')}
                  required={true}
                  errors={errors['CounterParty']?.message}
                />
              )}
            />
            <Controller
              name='PaymentRef'
              render={({ field }) => (
                <FormInput
                  {...field}
                  label={t('Payment Ref')}
                  errors={errors['PaymentRef']?.message}
                />
              )}
            />
            {(type[0] == '2' || log?.Type == '2') && (
              <Controller
                name='InvoiceRef'
                render={({ field }) => (
                  <FormInput
                    {...field}
                    label={t('Invoice Ref')}
                    errors={errors['InvoiceRef']?.message}
                  />
                )}
              />
            )}
            <Controller
              name='ValueDate'
              rules={{
                required: {
                  value: true,
                  message: requiredField,
                },
              }}
              render={({ field }) => (
                <FormDate
                  {...field}
                  label={t('Date')}
                  required={true}
                  minDate={false}
                />
              )}
            />
          </Grid>
          <div className={styles.buttonsContainer}>
            {log && (
              <>
                <Button
                  type={'fill'}
                  size={'medium'}
                  onClick={() => setChangingSettled(log)}>
                  {settledStatus ? t('Mark as pending') : t('Mark as settled')}
                </Button>
                <Button
                  type={'fill'}
                  size={'medium'}
                  onClick={() => {
                    setDeletingLog(log);
                  }}>
                  {t('Delete')}
                </Button>
              </>
            )}
            <Button
              type={'fill'}
              size={'medium'}
              onClick={handleSubmit(handleSubmitLog)}>
              {t('Save')}
            </Button>
          </div>
        </FormProvider>
      </div>
    </>
  );
};
