/**
 * Pagination component
 * description: page pagination that needs an anchor named: scroll-anchor inside the parent in order to center to it on page change.
 * @props position:
 */

import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationItem } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { useDealsContext } from '@lib/Context/Deals/deals.context';

import styles from './pagination.module.scss';
import './pagination.styles.scss';

const PaginationStyled = styled(Pagination)`
  .MuiPaginationItem-previousNext,
  .MuiPaginationItem-firstLast {
    border: none;
  }

  .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
    border-color: #d73964;
    background: none;
    color: #d73964;
  }

  .MuiButtonBase-root.MuiPaginationItem-root {
    border-color: #c4c4c4;
    color: #c4c4c4;
  }

  .MuiSvgIcon-root {
    font-size: 1em;
  }

  .MuiPaginationItem-root {
    margin: 0 2px;
    border-radius: 0;
    color: #555555;
    font-weight: 700;
    font-size: $fs-normal;
    line-height: 1;
  }

  .Mui-disabled {
    display: none;
  }

  .MuiPagination-ul {
    justify-content: center;
  }
`;

const PaginationComponent = ({ count, page }) => {
  const { filterColumnsArray, filterPage } = useDealsContext();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterColumnsArray]);

  useEffect(() => {
    setCurrentPage(filterPage.currentPage + 1);
  }, [filterPage]);

  const handleChange = (value) => {
    page(value); // send to the parent
    setCurrentPage(value);

    //Scroll to the top of the page
    const myEl = document.getElementById('scroll-anchor');
    myEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div className={`${styles.container} pagination-container`}>
      <Stack spacing={2}>
        <PaginationStyled
          count={count}
          onChange={(e, value) => handleChange(value)}
          variant='outlined'
          shape='rounded'
          showFirstButton
          page={currentPage}
          showLastButton
          renderItem={(item) => (
            <PaginationItem
              components={{
                last: KeyboardDoubleArrowRightIcon,
                first: KeyboardDoubleArrowLeftIcon,
              }}
              {...item}
            />
          )}
        />
      </Stack>
    </div>
  );
};

export default PaginationComponent;
