import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import CloseIcon from "@assets/icons/Close";
import dealsService from "@lib/services/deals-service";
import { useUserRole } from "@lib/hooks/useUserRole";
import { useWindowSize } from "@lib/hooks/useWindowSize";
import ConfirmationPopup from "@organisms/Popup/ConfirmationPopup/confirmation-popup.component";
import Popup from "@organisms/Popup/popup.component";

import "./styles.scss";

function StepsList({
  dealId,
  archivedDeal,
  steps,
  selectStepHandler,
  reloadStepList,
  reloadMessages,
}) {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { isAdmin, isOrgAdmin, isInsurer, isFinancier, isWarehouseAgent } =
    useUserRole();

  const [deletingStep, setDeletingStep] = useState(false);
  const [alert, setAlert] = useState({});

  const selectStep = (step) => {
    selectStepHandler(step.Id);
  };

  const deleteStep = () => {
    dealsService
      .deleteStep(dealId, deletingStep.Id)
      .then((r) => {
        setDeletingStep(false);
        reloadStepList();
        reloadMessages && reloadMessages();
        setAlert({
          state: "success",
          message: t(`Step {{name}} was deleted successfully!`, {
            name: deletingStep.Name,
          }),
        });
        setTimeout(() => {
          setAlert({});
        }, 4000);
      })
      .catch((e) => {
        if (e.response.data.status === 500) {
          setAlert({
            state: "error",
            message: t(`Server error! Please contact our support team.`),
          });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        } else {
          setAlert({ state: "error", message: e.response.data.message });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        }
      });
  };

  return (
    <React.Fragment>
      {deletingStep && (
        <ConfirmationPopup
          message={
            <Trans i18nKey="DeleteStep" values={{ name: deletingStep.Name }} />
          }
          cancelationHandler={() => {
            setDeletingStep(false);
          }}
          agreementHandler={deleteStep}
        />
      )}
      <div className="steps-list">
        <Popup message={alert.message} state={alert.state} />
        {steps &&
          Object.keys(steps).length > 0 &&
          Object.keys(steps).map((group, index1) => {
            if (Object.keys(steps[group]).length > 0) {
              return (
                <div className="step-group-container" key={index1}>
                  <p className="step-group">{t(group)}</p>
                  <div className="steps">
                    {Object.keys(steps[group]).map((key, index2) => {
                      if (steps[group][key].Abbreviation !== "APR") {
                        return (
                          <div className="step" key={index2}>
                            <div
                              className="step-content"
                              onClick={() => {
                                selectStep(steps[group][key]);
                              }}
                            >
                              <div
                                className={`step-logo ${
                                  steps[group][key].Done ? "done" : ""
                                }`}
                              >
                                {steps[group][key].Abbreviation}
                              </div>
                              <div className="step-border">
                                <div className="step-info">
                                  <h5>{t(steps[group][key].Name)}</h5>
                                  <p>{t(steps[group][key].Summary)}</p>
                                </div>
                                {!archivedDeal && (
                                  <div className="step-actions">
                                    {steps[group][key].Done ? (
                                      <button
                                        className="step-button view-step"
                                        onClick={() => {
                                          selectStep(steps[group][key]);
                                        }}
                                      >
                                        {t("VIEW")}
                                      </button>
                                    ) : (
                                      <button
                                        className="step-button edit-step"
                                        onClick={() => {
                                          selectStep(steps[group][key]);
                                        }}
                                      >
                                        {t("EDIT")}
                                      </button>
                                    )}
                                    {!archivedDeal &&
                                    !(
                                      steps[group][key].Abbreviation ===
                                        "RFQ" &&
                                      !isAdmin &&
                                      !isOrgAdmin
                                    ) ? (
                                      <>
                                        {!isFinancier &&
                                          !isInsurer &&
                                          !isWarehouseAgent && (
                                            <div
                                              className="remove-step"
                                              onClick={(e) => {
                                                setDeletingStep(
                                                  steps[group][key]
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              {width > 710 ? (
                                                <CloseIcon />
                                              ) : (
                                                <button className="step-button">
                                                  {t("DELETE")}
                                                </button>
                                              )}
                                            </div>
                                          )}
                                      </>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            } else return <React.Fragment></React.Fragment>;
          })}
      </div>
    </React.Fragment>
  );
}

export default StepsList;
