import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, Controller, useForm } from 'react-hook-form';
import FormInputFields from '@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component';
import Button from '@atoms/Button/button.component';
import commonService from '@lib/services/common-service';
import PageLoader from '@templates/PageLoader/page-loader.template';
import { useTranslation } from 'react-i18next';

import styles from '../reports.module.scss';

export const LedgerReport = () => {
  const [organizations, setOrganizations] = useState({});
  const [countries, setCountries] = useState({});
  const [statuses, setStatuses] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const form = useForm();

  const { handleSubmit, watch } = form;

  useEffect(() => {
    const getOptions = async () => {
      try {
        const resultOrgs = await commonService.getOrganizations();
        setOrganizations(resultOrgs.data);
        const resultCountries = await commonService.getCountries();
        setCountries(resultCountries.data);
        const resultStatuses = await commonService.getStatuses();
        setStatuses(resultStatuses.data);
      } catch (error) {
        console.log(error);
      }
    };

    getOptions();
  }, []);

  const handleGenerate = async (formData) => {
    setIsLoading(true);
    try {
      const fileName = `${
        formData?.country ? countries[formData?.country] + ' ' : ''
      }Ops Ledger.xlsx`;

      const payload = {
        from: formData?.from || '',
        to: formData?.to || '',
        organizationId: formData?.organization || '',
        countryId: formData?.country || '',
        countryName: countries[formData?.country] || '',
        status: formData?.status || '',
      };

      await commonService
        .generateLedgerReport(payload)
        .then((response) => {
          const blobFile = window.URL.createObjectURL(
            new Blob([response.data])
          );

          const link = document.createElement('a');
          link.href = blobFile;
          link.setAttribute('download', fileName);
          link.click();
          form.reset();
        })
        .catch(console.error);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fields = [
    {
      Key: 'fromDate',
      Label: t('From'),
      Type: 'date',
      Options: null,
      Readonly: 0,
      Value: null,
      GridNumber: 4,
      Required: null,
    },
    {
      Key: 'toDate',
      Label: t('To'),
      Type: 'date',
      Options: null,
      Readonly: 0,
      Value: null,
      GridNumber: 4,
      Required: null,
    },
    {
      Key: 'organization',
      Label: t('Organization'),
      Type: 'dropdown',
      Options: organizations,
      Readonly: 0,
      Value: null,
      GridNumber: 4,
      Required: null,
      Searchable: 'true',
    },
    {
      Key: 'country',
      Label: t('Country'),
      Type: 'dropdown',
      Options: countries,
      Readonly: 0,
      Value: null,
      GridNumber: 4,
      Required: null,
      Searchable: 'true',
    },
    {
      Key: 'status',
      Label: t('Deal Status'),
      Type: 'dropdown',
      Options: statuses,
      Readonly: 0,
      Value: null,
      GridNumber: 4,
      Required: null,
      Searchable: 'true',
    },
  ];

  return (
    <div>
      <PageLoader isLoading={isLoading}>
        <FormProvider {...form}>
          <Grid container className={styles.mainForm}>
            <Grid item display={'flex'} flexDirection={'row'} width={'100%'}>
              <Controller
                name='from'
                render={({ field }) => {
                  return (
                    <Grid columns={4} className={styles.inputField}>
                      <FormInputFields
                        info={{ ...field }}
                        fields={fields[0]}
                        volumeUnit={''}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        required={false}
                        disabled={false}
                        minDate={false}
                      />
                    </Grid>
                  );
                }}
              />
              <Controller
                name='to'
                render={({ field }) => {
                  return (
                    <Grid columns={4} className={styles.inputField}>
                      <FormInputFields
                        info={{ ...field }}
                        fields={fields[1]}
                        volumeUnit={''}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        required={false}
                        disabled={false}
                        minDate={true}
                        minDateValue={watch('from')}
                      />
                    </Grid>
                  );
                }}
              />
              <Controller
                name='organization'
                render={({ field }) => {
                  return (
                    <Grid columns={4} className={styles.inputField}>
                      <FormInputFields
                        info={{ ...field }}
                        fields={fields[2]}
                        volumeUnit={''}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        required={false}
                        disabled={false}
                        minDate={false}
                        clearable={true}
                      />
                    </Grid>
                  );
                }}
              />
            </Grid>
            <Grid item display={'flex'} flexDirection={'row'} width={'100%'}>
              <Controller
                name='country'
                render={({ field }) => {
                  return (
                    <Grid columns={4} className={styles.inputField}>
                      <FormInputFields
                        info={{ ...field }}
                        fields={fields[3]}
                        volumeUnit={''}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        required={false}
                        disabled={false}
                        minDate={false}
                        clearable={true}
                      />
                    </Grid>
                  );
                }}
              />
              <Controller
                name='status'
                render={({ field }) => {
                  return (
                    <Grid columns={4} className={styles.inputField}>
                      <FormInputFields
                        info={{ ...field }}
                        fields={fields[4]}
                        volumeUnit={''}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        required={false}
                        disabled={false}
                        minDate={false}
                        clearable={true}
                      />
                    </Grid>
                  );
                }}
              />
              <Controller
                name=''
                render={({ field }) => {
                  return (
                    <Grid columns={4} className={styles.inputField}></Grid>
                  );
                }}
              />
            </Grid>
          </Grid>
          <div className={styles.buttonContainer}>
            <Button
              size={'large'}
              type={'fill'}
              onClick={async () => {
                await handleSubmit(handleGenerate)();
              }}>
              {t('Generate')}
            </Button>
          </div>
        </FormProvider>
      </PageLoader>
    </div>
  );
};
